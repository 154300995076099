import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkButtonComponent } from './link-button.component';
import { IconModule } from '../icon/icon.module';



@NgModule({
  declarations: [
    LinkButtonComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ],
  exports: [
    LinkButtonComponent
  ]
})
export class LinkButtonModule { }
